import {
    Button,
    Divider,
    Grid,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import { useContext, useMemo, useState } from "react";
import { Div, DivHor } from "../../components/base/Divs";
import TopBar from "../../components/common/TopBar";
import { Header } from "../../components/forms/Header";
import { Label } from "../../components/forms/Label";
import { Tab } from "../../components/forms/Tab";
import { AuthContext } from "../../context/AuthContext";
import { post } from "../../network";
import { shadow } from "../../shadows";
import { faq } from "../../values/faq";
import DisclaimerText from "../charging/components/DisclaimerText";

const QnA = ({ question, answer }: { question: string; answer: string }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    return (
        <Div
            onClick={() => setOpen((o) => !o)}
            style={{
                borderBottomColor: theme.palette.neutral.light,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                alignItems: "stretch",
            }}
        >
            <Typography
                style={{
                    fontWeight: 500,
                    fontSize: 14,
                    color: theme.palette.primary.main,
                }}
            >
                {question}
            </Typography>
            {open && (
                <Typography
                    style={{
                        fontStyle: "italic",
                        fontSize: 14,
                        padding: "8px 0px",
                        color: theme.palette.primary.main,
                    }}
                >
                    {answer}
                </Typography>
            )}
        </Div>
    );
};

export default function SupportScreen() {
    const [mode, setMode] = useState<"FAQ" | "CONTACT">("FAQ");
    const { loggedIn } = useContext(AuthContext);
    const theme = useTheme();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [buttonText, setButtonText] = useState("SEND");

    const submit = async () => {
        if (message.trim().length > 0) {
            await post("me/contact", { message });
            setMessage("");
            setButtonText("SUBMITTED!");
            setTimeout(() => {
                reset();
            }, 4000);
        }
    };

    const canSubmit = useMemo(() => {
        if (!loggedIn) {
            return (
                firstName.trim().length > 0 &&
                lastName.trim().length > 0 &&
                email.trim().length > 0 &&
                message.trim().length > 0
            );
        }
        return message.trim().length > 0;
    }, [message, firstName, lastName, email, loggedIn]);

    const reset = () => {
        setButtonText("Submit");
    };

    return (
        <>
            <TopBar title="Support & Help" back />
            <Grid
                container
                direction="column"
                alignItems="center"
                style={{
                    flex: 1,
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    backgroundColor: theme.palette.background.default,
                }}
            >
                <DivHor
                    style={{
                        justifyContent: "space-evenly",
                        padding: 0,
                        zIndex: 101,
                        alignItems: "stretch",
                        ...shadow,
                    }}
                >
                    <Tab
                        title="FAQ"
                        active={mode === "FAQ"}
                        onClick={() => setMode("FAQ")}
                    />
                    <Tab
                        title="Contact"
                        active={mode === "CONTACT"}
                        onClick={() => setMode("CONTACT")}
                    />
                </DivHor>
                {mode === "FAQ" && (
                    <Div style={{ alignItems: "stretch" }}>
                        {faq.map(({ question, answer }, index) => {
                            return (
                                <QnA
                                    key={`question-${index}`}
                                    question={`${index + 1}. ${question}`}
                                    answer={answer}
                                />
                            );
                        })}
                    </Div>
                )}
                {mode === "CONTACT" && (
                    <Div style={{ alignItems: "stretch", padding: "24px" }}>
                        <Header
                            title="How can we help?"
                            subtitle="Send us a message"
                        />
                        {!loggedIn && (
                            <>
                                <DivHor style={{ padding: 0 }}>
                                    <Div
                                        style={{
                                            alignItems: "flex-start",
                                            padding: 0,
                                            marginRight: 14,
                                        }}
                                    >
                                        <Label title="First Name" />
                                        <TextField variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </Div>
                                    <Div
                                        style={{
                                            alignItems: "flex-start",
                                            padding: 0,
                                        }}
                                    >
                                        <Label title="Last Name" />
                                        <TextField variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </Div>
                                </DivHor>
                                <Label title="Email" />
                                <TextField variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </>
                        )}
                        <Label title="Your message" />
                        <TextField
                            variant="outlined"
                            multiline
                            rows={2}
                            rowsMax={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            style={{
                                margin: "14px 0px",
                                color: theme.palette.secondary.contrastText,
                            }}
                            onClick={submit}
                            disabled={!canSubmit}
                        >
                            {buttonText}
                        </Button>
                        <DisclaimerText text="We will get back to you urgently in 3 working days" />
                        <Divider style={{ marginTop: 14, marginBottom: 14 }} />
                        <Label
                            title="Urgent Hotline"
                            subtitle="Monday - Friday only / 9.00am - 5.00pm"
                        />
                        <Button
                            startIcon={<Phone />}
                            style={{
                                color: theme.palette.secondary.light,
                                alignSelf: "flex-start",
                                fontSize: 18,
                            }}
                        >
                            +65 98764312
                        </Button>
                    </Div>
                )}
            </Grid>
        </>
    );
}
