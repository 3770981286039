export const faq = [
  {
    question: "How to charge?",
    answer: "Look for the scan button on Map screen",
  },
  {
    question: "What happens if...",
    answer: "I don't know",
  },
  {
    question: "What happens if...",
    answer: "I don't know",
  },
];
