import { Location } from "./typings";
import { isAndroid, isIOS } from "react-device-detect";
import { CropLandscapeOutlined } from "@material-ui/icons";

export function distanceBetween(
  c1: { latitude: number; longitude: number },
  c2: { latitude: number; longitude: number },
) {
  const { latitude: lat1, longitude: lon1 } = c1;
  const { latitude: lat2, longitude: lon2 } = c2;
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export const openLocationUrl = (location: Location) => {
  let baseUrl = ''
  if (isAndroid) {
    baseUrl = `https://www.google.com/maps/dir/?api=1&`;
  }
  if (isIOS) {
    baseUrl = `maps://maps.apple.com/?daddr=`
  }
  const url = `${baseUrl}destination=${location.address}`;
  const win = window.open(url);
  win.focus();
};

export const shareLocationUrl = (location: Location) => {
  const baseUrl = `https://www.google.com/maps/dir/?api=1&`;
  const url = `${baseUrl}destination=${location.address}`;
  if ((window.navigator as any).canShare && (window.navigator as any).canShare({ url })) {
    (window.navigator as any).share({ url });
  }
};

export const openStoreLink = ({ playStoreURL, appStoreURL }) => {
  if (isAndroid) {
    window.open(playStoreURL, "_blank")
  }
  if (isIOS) {
    window.open(appStoreURL, "_blank")
  }
}

export const darkModeStyle = [
  { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#6b9a76" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
]

export const sortingFunc = (a: Location, b: Location, mode: string = 'CHEAPEST', position: { latitude: number, longitude: number }): number => {
  console.log('SORTING VIA', mode)
    if (mode === "MOST VISITED") {
        return a?.timesVisited - b?.timesVisited;
    }
    if (mode === "CHEAPEST") {
        return (
            a?.evses?.[0]?.chargingRateKWH - b?.evses?.[0]?.chargingRateKWH
        );
    }
    if (mode === "NEAREST" && position) {
        const distanceA = distanceBetween(a.coordinates, position);
        const distanceB = distanceBetween(b.coordinates, position);
        console.log('ab', distanceA, distanceB)
        return distanceA - distanceB;
    }
    return 1;
};